<template>
  <Form class="form" :model="formModel" :label-width="80">
    <FormItem label="旧密码" prop="oldPassword">
      <Input
        v-model="formModel.oldPassword" type="password" password placeholder="请输入旧密码" />
    </FormItem>
    <FormItem class="form-item" label="新密码" prop="newPassword">
      <Input v-model="formModel.newPassword" type="password" password placeholder="请输入新密码" />
      <span class="tip">密码必须包含大小写字母和数字，长度5-10位</span>
    </FormItem>
    <FormItem label="确认密码" prop="confirmPassword">
      <Input v-model="formModel.confirmPassword" type="password" password placeholder="请输入确认密码" />
    </FormItem>
    <FormItem>
      <Button type="primary" @click="modifyPassword">确认</Button>
    </FormItem>
  </Form>
</template>

<script>
import ModifyPasswordApi from './modify-password.api';

export default {
  name: 'reset-password',
  data() {
    return {
      formModel: {
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
      },
    };
  },
  methods: {
    modifyPassword() {
      const { oldPassword, newPassword, confirmPassword } = this.formModel;
      ModifyPasswordApi.modifyPassword(oldPassword, newPassword, confirmPassword).then(() => {
        this.formModel = {
          oldPassword: '',
          newPassword: '',
          confirmPassword: '',
        };
      });
    },
  },
};
</script>

<style lang="less" scoped>
.form {
  max-width: 400px;
}
.form-item {
  position: relative;
}
.tip {
  position: absolute;
  bottom: calc(~"-100% + 6px");
  left: 0;
  font-size: 12px;
  color: #999;
}
</style>
