import axios from '@/plugins/axios';

class ModifyPasswordApi {
  static modifyPassword(oldPassword, newPassword, confirmPassword) {
    return axios({
      url: '/api/sys/account/updatepwd',
      data: {
        old_password: oldPassword,
        new_password: newPassword,
        confirm_password: confirmPassword,
      },
      method: 'post',
      headers: {
        meta: {
          desc: '修改密码',
        },
      },
    });
  }
}

export default ModifyPasswordApi;
